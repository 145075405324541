import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { useAuthContext } from '../../context/auth';
import Header from '../Header';

const Root = () => {
	const { isAuth } = useAuthContext();

	return (
		<React.Fragment>
			{isAuth ? (
				<Main>
					<Header />

					<section>
						<Outlet />
					</section>
				</Main>
			) : (
				<Navigate to="login" state={{ isReplace: true }} replace />
			)}
		</React.Fragment>
	);
};
const Main = styled.main`
	> section {
		position: relative;
		padding: 11.4rem 2rem 2rem;
		min-height: 100vh;
	}
`;

export default Root;
