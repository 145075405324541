import React, { useMemo } from 'react';

import styled, { type CSSProperties } from 'styled-components';

import type usePagination from './hooks/usePagination';

interface PaginationProps {
	pagination: ReturnType<typeof usePagination>;
	style?: CSSProperties;
	className?: string;
}

type ChangeType = 'FIRST' | 'PREV' | 'PAGE' | 'NEXT' | 'LAST';

interface HandlePage {
	(type: ChangeType): void;
	(type: ChangeType, page: number): void;
}

const Pagination = ({ pagination, style, className }: PaginationProps) => {
	const { curPage, totalPage, startPage, endPage, setCurPage } = pagination;

	const pages = useMemo(() => {
		const length = endPage - startPage + 1;
		return Array.from({ length: length || 1 }, (_, idx) => startPage + idx);
	}, [endPage, startPage]);

	const handlePage: HandlePage = (type: ChangeType, page?: number) => {
		try {
			switch (type) {
				case 'FIRST':
					setCurPage(1);
					break;
				case 'PREV':
					setCurPage(curPage - 1);
					break;
				case 'PAGE':
					if (page) setCurPage(page);
					break;
				case 'NEXT':
					setCurPage(curPage + 1);
					break;
				case 'LAST':
					setCurPage(totalPage);
					break;
				default:
					break;
			}
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Container style={style} className={className}>
			{/* 맨앞으로 가기 버튼 */}
			<button
				onClick={() => {
					handlePage('FIRST');
				}}
				className="move"
				disabled={curPage === 1}
			></button>
			{/* 앞으로가기 버튼 */}
			<button
				onClick={() => {
					handlePage('PREV');
				}}
				className="move"
				disabled={curPage === 1}
			></button>
			<ul className="page">
				{pages.map((page) => (
					<li key={'pagination_' + page} className={`page-item${page === curPage ? ' active' : ''}`}>
						<button
							type="button"
							onClick={() => {
								handlePage('PAGE', page);
							}}
						>
							{page}
						</button>
					</li>
				))}
			</ul>
			{/* 뒤로가기 버튼 */}
			<button
				onClick={() => {
					handlePage('NEXT');
				}}
				className="move"
				disabled={curPage === totalPage || !totalPage}
			></button>
			{/* 맨 뒤로가기 버튼 */}
			<button
				onClick={() => {
					handlePage('LAST');
				}}
				className="move"
				disabled={curPage === totalPage || !totalPage}
			></button>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	gap: 0.6rem;
	justify-content: center;
	button {
		background-color: transparent;
		padding: 0;
		border: none;
	}
	ul,
	li {
		padding: 0;
		margin: 0;
		list-style: none;
	}
	.move {
		transition: 0.3s opacity;
		background-size: 30px 30px;
		width: 30px;
		height: 30px;
		padding: 0px;

		&:nth-child(1) {
			background: url('/assets/others/page_first.png');
		}
		&:nth-child(2) {
			background: url('/assets/others/page_pre.png');
		}
		&:nth-last-child(1) {
			background: url('/assets/others/page_next.png');
		}
		&:nth-last-child(2) {
			background: url('/assets/others/page_last.png');
		}
		&:disabled {
			opacity: 0.4;
			cursor: auto;
		}
	}
	.page {
		display: flex;
		gap: 0.6rem;

		&-item {
			button {
				width: 30px;
				height: 30px;
				border-radius: 2px;
				padding: 10px;
				display: flex;
				align-items: center;
				justify-content: center;

				font-weight: 400;
			}

			&.active {
				button {
					color: #2cc2bc;
					border: solid 1px #2cc2bc;
				}
			}
		}
	}
`;

export default Pagination;
