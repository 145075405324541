import { useQuery } from '@tanstack/react-query';

import { api } from '..';

export const applicationKeys = {
	all: ['application'],
	getApplicationVersions: () => [...applicationKeys.all, 'versions'],
} as const;

export const useGetApplicationVersions = () =>
	useQuery({
		queryKey: applicationKeys.getApplicationVersions(),
		queryFn: async () => await api.application.getApplicationVersions(),
	});
