import React from 'react';

import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { VStack } from '../../../components/Common';
import { Icon } from '../../../components/Common/Icon';
import { ICON_ID } from '../../../components/Common/Icon/const';
import { type UsePaginationResponse } from '../../../components/Pagination/hooks/usePagination';
import { type Pds } from '../../../services/pds/types';
import { calcIndex } from '../../../utils/calcIndex';
import { formatDate } from '../../../utils/format';

const convertByteToMbAndKb = (byte: number) =>
	byte / 1024 > 1024 ? `${Math.floor(byte / 1024 / 1024)}Mb` : `${Math.floor(byte / 1024)}Kb`;
interface RowProps {
	data: Pds;
	idx: number;
	paging: UsePaginationResponse;
}
const Row = ({ data, idx, paging }: RowProps) => {
	const [isOpen, setOpen] = React.useState(false);
	return (
		<React.Fragment>
			<Header>
				<td>
					<Button
						className="icon__button"
						data-active={isOpen}
						$buttonType="NONE"
						$height="auto"
						onClick={() => {
							setOpen(!isOpen);
						}}
					/>
				</td>
				<td>{calcIndex(paging, idx)}</td>
				<td>{data.title}</td>
				<td>{formatDate(new Date(data.createdAt), 'yyyy-MM-dd hh:mm:ss')}</td>
			</Header>
			<Data $isOpen={isOpen}>
				<td colSpan={6} className="not">
					<VStack className="inner">
						<div className="inner__html" dangerouslySetInnerHTML={{ __html: data.content }}></div>
						{data.files.length > 0 && (
							<table>
								<thead>
									<tr>
										<th>파일 이름</th>
										<th>파일 크기</th>
										<th>등록일</th>
										<th>다운로드</th>
									</tr>
								</thead>
								<tbody>
									{data.files.map((file) => (
										<tr key={'collapsible__row--' + idx + '--' + file.id}>
											<td>{file.name}</td>
											<td>{convertByteToMbAndKb(file.size)}</td>
											<td>{file.createdAt}</td>
											<td>
												<Button
													$buttonType="LINE"
													$height="auto"
													$padding="0.4rem"
													$color="PRIMARY"
													onClick={() => {
														window.open(file.url, '_blank');
													}}
												>
													<Icon iconID={ICON_ID.FILE} width={'2.4rem'} height={'2.4rem'} $defaultColor="primary_600" />
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						)}
					</VStack>
				</td>
			</Data>
		</React.Fragment>
	);
};

const Header = styled.tr`
	.icon__button {
		width: 2.4rem;
		height: 2.4rem;
		border-radius: 99px;
		&:hover {
			background-color: ${({ theme }) => theme.colors.gray[200]};
		}
		&::after {
			content: '';
			width: 1rem;
			height: 1rem;
			box-sizing: border-box;
			position: relative;
			top: -0.1rem;
			border-top: 2px solid ${({ theme }) => theme.colors.gray[700]};
			border-right: 2px solid ${({ theme }) => theme.colors.gray[700]};
			transform: rotate(135deg);
		}
		&[data-active='true'] {
			&::after {
				top: 0.1rem;
				transform: rotate(-45deg);
			}
		}
	}
`;
const Data = styled.tr<{ $isOpen: boolean }>`
	.inner {
		max-height: ${({ $isOpen }) => ($isOpen ? '100rem' : 0)};

		padding: ${({ $isOpen }) => ($isOpen ? '2rem' : '0 2rem')};
		overflow: hidden;
		transition:
			0.3s max-height,
			0.3s padding;
	}
	.inner__html {
		all: initial;
	}
	table {
		display: flex;
		flex-direction: column;
		tr {
			display: flex;
			td,
			th {
				flex: 1;
			}
		}
		td {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`;

export default Row;
