import React from 'react';

import styled from 'styled-components';

import { Button } from '../../components/Button';
import { removeToken } from '../../utils/handleToken';

const Header = () => {
	return (
		<Wrapper>
			GoodPharm Downloader
			<Button
				onClick={() => {
					removeToken();
				}}
			>
				로그아웃
			</Button>
		</Wrapper>
	);
};

const Wrapper = styled.header`
	position: fixed;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	z-index: 1100;
	align-items: center;
	height: 6.4rem;
	padding: 0 2.4rem;
	background-color: ${({ theme }) => theme.colors.primary[600]};
	color: ${({ theme }) => theme.colors.white};
	${({ theme }) => theme.font.title[1]};
	box-shadow:
		0px 2px 4px -1px rgba(0, 0, 0, 0.2),
		0px 4px 5px 0px rgba(0, 0, 0, 0.14),
		0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`;

export default Header;
