import React from 'react';

import styled from 'styled-components';

import { HStack } from '../../components/Common';
import CollapsibleTable from '../../feature/Main/CollapsibleTable';
import ListTable from '../../feature/Main/ListTable';

const Main = () => {
	return (
		<Wrapper $justifyContent="center" $gap="3rem">
			<ListTable />
			<CollapsibleTable />
		</Wrapper>
	);
};

const Wrapper = styled(HStack)`
	@media screen and (max-width: 1140px) {
		flex-direction: column;
	}
`;

export default Main;
