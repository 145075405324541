import React from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Button } from '../../components/Button';
import { HStack, VStack } from '../../components/Common';
import { Input } from '../../components/Input';
import { useValues } from '../../hooks/useValues';
import { usePostPdsPassword } from '../../services/pds/queries';
import { type PostPasswordReq } from '../../services/pds/types';
import { setToken } from '../../utils/handleToken';

const INITIAL_VALUES: PostPasswordReq = {
	password: '',
} as const;
const Login = () => {
	const navigate = useNavigate();
	const { values, onChangeValues } = useValues(INITIAL_VALUES);
	const { mutate } = usePostPdsPassword();

	const onSubmit = React.useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			mutate(values, {
				onSuccess: (res) => {
					setToken(res.token);
					navigate('/', { relative: 'path' });
				},
				onError: (err) => {
					toast.error(err.response?.data.message);
				},
			});
		},
		[values],
	);
	return (
		<Wrapper $alignItems="center" $justifyContent="center" $gap="2rem">
			<div className="logo">
				<img src={'/assets/logo/logo.png'}></img>
				<span>굿팜</span>
				<span>다운로더</span>
			</div>
			<form {...{ onSubmit }}>
				<HStack $gap="1.2rem">
					<Input name="password" onChange={onChangeValues} type="password" placeholder="비밀번호를 입력해주세요." />
					<Button type="submit">로그인</Button>
				</HStack>
			</form>
		</Wrapper>
	);
};

const Wrapper = styled(VStack)`
	height: 100vh;

	.logo {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: center;
		font-size: 40px;
		font-weight: bold;
		font-family: monospace;
		flex-direction: row;
		img {
			width: calc(5rem + 3vw);
			height: calc(5rem + 3vw);
			padding: 0;
			font-size: 0;
			margin: 0;
		}
		span {
			&:nth-child(2) {
				font-size: calc(2.4rem + 2.5vw);
				color: ${({ theme }) => theme.colors.primary[600]};
			}
			&:nth-child(3) {
				font-size: calc(2.4rem + 2.5vw);
			}
		}
	}
`;

export default Login;
