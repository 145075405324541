import React, { forwardRef, type InputHTMLAttributes } from 'react';

import styled, { css } from 'styled-components';

import { type FontKeys, getFontStyle } from '../../lib/styled-components/function';
import { type Align } from '../Common/types';

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
	$font?: FontKeys;
	$padding?: string;
	$height?: string;
	$textAlign?: Align;
	$fontWeight?: number;
}

export const Input = forwardRef<HTMLInputElement, TextFieldProps>(
	(props: TextFieldProps, ref?: React.ForwardedRef<HTMLInputElement>) => {
		const {
			$font = 'body_2',
			$padding = '0.7rem 1.2rem',
			$height,
			style = {},
			readOnly = false,
			disabled = false,
			onChange,
			...rest
		} = props;

		return (
			<Container {...{ style, $padding, $height }} $readOnly={readOnly} $disabled={disabled}>
				{/* {renderPrefix && <RenderWrap>{renderPrefix}</RenderWrap>} */}
				<CustomInput
					{...rest}
					{...{ $font }}
					ref={ref}
					readOnly={readOnly}
					disabled={disabled}
					onWheel={(e) => {
						if ((e.target as HTMLInputElement).type === 'number') (e.target as HTMLInputElement).blur();
					}}
					onChange={(e) => {
						if (e.target.type === 'number') {
							e.target.value = e.target.value.replace(/\D/g, '');
						}
						onChange && onChange(e);
					}}
				/>
				{/* {renderSuffix && <RenderWrap>{renderSuffix}</RenderWrap>} */}
			</Container>
		);
	},
);

Input.displayName = 'TextField';

interface ContainerProps extends Pick<TextFieldProps, '$padding' | '$height'> {
	$readOnly: boolean;
	$disabled: boolean;
}

const Container = styled.div<ContainerProps>`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	align-self: stretch;
	gap: 0.8rem;
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.gray[300]};

	${({ $padding, $height }) => css`
		padding: ${$padding};
		height: ${$height};
	`}

	background: ${({ theme, $readOnly, $disabled }) =>
		($readOnly ?? $disabled) ? theme.colors.gray[100] : theme.colors.white};

	&:focus-within {
		border: 1px solid ${({ theme, $readOnly }) => ($readOnly ? theme.colors.gray[300] : theme.colors.primary[600])};
	}
`;

const CustomInput = styled.input<Pick<TextFieldProps, '$font' | '$fontWeight' | '$textAlign'>>`
	border: 0;
	${({ $textAlign, $fontWeight, $font }) => css`
		${getFontStyle($font)};
		text-align: ${$textAlign};
		font-weight: ${$fontWeight};
	`}
	min-width: 0;
	position: relative;
	width: 100%;
	&::placeholder {
		color: ${({ theme }) => theme.colors.gray[600]};
	}
	caret-color: ${({ theme }) => theme.colors.primary[600]};
	&:read-only,
	&:disabled {
		background: ${({ theme }) => theme.colors.gray[100]};
	}
	&[type='number'] {
		text-align: right;
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}
	}
`;
