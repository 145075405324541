import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    * {
        padding:0;
        margin:0;
        box-sizing: border-box;
    }
    html {
        font-size: 62.5%;
    }
    body {
        margin: 0;
        font-size: 1.6rem;
    }
    button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        &:disabled {
            cursor: default;
        }
    }


    html,
    body, 
    div, 
    span, 
    h1, 
    h2, 
    h3, 
    h4, 
    h5, 
    h6, 
    p, 
    a, 
    ol, 
    ul, 
    li, 
    form, 
    label, 
    table, 
    caption, 
    tbody, 
    tfoot, 
    thead, 
    tr, 
    th, 
    td, 
    article,
    aside,
    footer,
    header,
    nav,
    section, 
    button {
        font-family: Pretendard, Helvetica, sans-serif;
    }   

input {
    outline: none;
    ::placeholder {
        color: ${({ theme }) => theme.colors.gray[600]};
    }
}
button:disabled {
    pointer-events: none;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black}
}
a, button, textarea {
  outline:none;
}

    .Toastify__toast{
        &-body > div {
            ${({ theme }) => theme.font.body[1]};
            font-weight: 500;
            color: ${({ theme }) => theme.colors.white};
            width:4rem;
            &:last-child::before {
                display: block;
                ${({ theme }) => theme.font.title[2]};
                font-weight: 700;
            }
        }
        &-icon {
            width: max-content;
            svg {
                display: none;
            }
            &::before {
                content: "";
                display: inline-block;
                width: 4rem;
                height: 4rem;
                background-position: center center;
                background-size: cover;
            }
        }
        &-container {
            width: 40rem;
        }

        &-theme--colored {
            &.Toastify__toast {
              white-space: pre-wrap;
                &--error {
                    background-color: ${({ theme }) => theme.colors.red[500]} !important;
                    .Toastify__toast-icon::before {
                        background-image: url("/assets/others/toast-error.svg");
                    }
                }
                &--success {
                    background-color: ${({ theme }) => theme.colors.green[600]} !important;
                    .Toastify__toast-icon::before {
                        background-image: url("/assets/others/toast-success.svg");
                    }
                }
                &--info {
                    background-color: ${({ theme }) => theme.colors.blue[600]} !important;
                    .Toastify__toast-icon::before {
                        background-image: url("/assets/others/toast-info.svg");
                    }
                }
            }
            
        }
        &--error > .Toastify__toast-body > div:last-child::before {
            content:"Error";
        }
        &--success > .Toastify__toast-body > div:last-child::before {
            content:"Success";
        }
        &--info > .Toastify__toast-body > div:last-child::before {
            content:"Information";
        }
    }
`;

export default GlobalStyle;
