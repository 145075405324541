import Theme, { type ColorsType, type FontType } from './Theme';

export const SEPARATOR = '_';

type NestedKeys<T> = {
	[K in keyof T]: T[K] extends object ? `${Extract<K, string>}${typeof SEPARATOR}${Extract<keyof T[K], number>}` : K;
}[keyof T];

export type ColorKeys = NestedKeys<ColorsType>;

export type FontKeys = NestedKeys<FontType>;

export const getFontStyle = (key?: FontKeys): string | undefined => {
	if (!key) return undefined;
	const keys: Array<string | number> = key.split(SEPARATOR).map((k) => (isNaN(Number(k)) ? k : Number(k)));

	let current: Record<string, Record<number, string>> | Record<number, string> | string = Theme.font;

	keys.forEach((k) => {
		if (typeof current !== 'object') return;
		if (typeof k === 'string' && k in current) {
			current = (current as Record<string, Record<number, string>>)[k];
		} else if (typeof k === 'number' && k in current) {
			current = (current as Record<number, string>)[k];
		}
	});

	return typeof current === 'string' ? current : undefined;
};

export const getColorStyle = (key?: ColorKeys): string | undefined => {
	if (!key) return undefined;
	const keys = key.split(SEPARATOR).map((k) => (isNaN(Number(k)) ? k : Number(k)));
	let current: Record<string, string | Record<number, string>> | Record<number, string> | string = Theme.colors;
	keys.forEach((k) => {
		if (typeof current !== 'object') return;
		if (typeof k === 'string' && k in current) {
			current = (current as Record<string, string | Record<number, string>>)[k];
		} else if (typeof k === 'number' && k in current) {
			current = (current as Record<number, string>)[k];
		}
	});
	return typeof current === 'string' ? current : undefined;
};
