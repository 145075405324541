import { application } from './application';
import { pds } from './pds';
import instance from '../lib/axios/instance';

import type { AxiosRequestConfig, AxiosResponse } from 'axios';

export const Get = async <T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
	return await instance.get(url, config);
};

export const Post = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
	return await instance.post(url, data, config);
};

export const Put = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
	return await instance.put(url, data, config);
};

export const Delete = async <T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
	return await instance.delete(url, config);
};

export const Patch = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
	return await instance.patch(url, data, config);
};

export const api = { application, pds };
