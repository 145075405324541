import React from 'react';

import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import Root from '../../layout/Root';
import Pages from '../../pages';

const router = createBrowserRouter([
	{
		path: '/login',
		element: <Pages.Login />,
	},
	{
		path: '/',
		element: <Root />,
		children: [
			{
				path: '',
				element: <Pages.Main />,
			},
			{
				path: '*',
				element: <Navigate to="/" />,
			},
		],
	},
]);

const Routers = () => {
	return <RouterProvider router={router} />;
};

export default Routers;
