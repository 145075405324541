import React from 'react';

import styled from 'styled-components';

import Row from './Row';
import { VStack } from '../../../components/Common';
import Pagination from '../../../components/Pagination';
import { useGetPds } from '../../../services/pds/queries';

const CollapsibleTable = () => {
	const { data, isSuccess, pagination } = useGetPds();
	return (
		<Wrapper $gap="1.6rem">
			{isSuccess && (
				<React.Fragment>
					<VStack className="table--wrapper">
						<table className="table">
							<thead>
								<tr>
									<th></th>
									<th>순번</th>
									<th>제목</th>
									<th>등록일</th>
								</tr>
							</thead>
							<tbody>
								{data.content.map((pds, idx) => (
									<Row key={'collapsible__row--' + pds.id} data={pds} idx={idx} paging={pagination} />
								))}
							</tbody>
						</table>
					</VStack>
					<Pagination pagination={pagination} />
				</React.Fragment>
			)}
		</Wrapper>
	);
};

const Wrapper = styled(VStack)`
	width: 100%;
	font-size: 1.4rem;
	.table--wrapper {
		max-height: 68rem;
		overflow-y: auto;
		.table {
			border-collapse: collapse;
			border: 1px solid ${({ theme }) => theme.colors.gray[300]};

			th,
			td {
			}
			thead,
			tbody {
				tr {
					th,
					td {
						&:not(.not) {
							padding: 1.6rem;
						}
						border-bottom: 1px solid ${({ theme }) => theme.colors.gray[300]};
						text-align: center;
					}
					th:nth-child(1),
					td:nth-child(1) {
					}
				}
			}
		}
	}
`;
export default CollapsibleTable;
