import React from 'react';

import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Common/Icon';
import { ICON_ID } from '../../../components/Common/Icon/const';
import { useGetApplicationVersions } from '../../../services/application/queries';
import { formatDate } from '../../../utils/format';

const HEADER_TITLE = ['게시일시', '프로그램명', '버전', '다운로드'] as const;
const ListTable = () => {
	const { data, isSuccess } = useGetApplicationVersions();

	return (
		<Wrapper>
			<div className="row">
				{HEADER_TITLE.map((title) => (
					<div key={'title--' + title} className="item">
						{title}
					</div>
				))}
			</div>
			{isSuccess &&
				data.map((el, idx) => (
					<div className="row" key={'data--row' + idx}>
						<div className="item">{formatDate(new Date(el.updatedAt), 'yyyy-MM-dd hh:mm:ss')}</div>
						<div className="item">{el.typeName}</div>
						<div className="item">{el.version}</div>
						<div className="item">
							<Button
								$buttonType="LINE"
								$height="auto"
								$padding="0.4rem"
								$color="PRIMARY"
								onClick={() => {
									window.open(el.url, '_blank');
								}}
							>
								<Icon iconID={ICON_ID.FILE} width={'2.4rem'} height={'2.4rem'} $defaultColor="primary_600" />
							</Button>
						</div>
					</div>
				))}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	flex-direction: column;
	.row {
		display: flex;
		align-items: center;
		padding: 8px 0;
		border-bottom: 1px ${({ theme }) => theme.colors.gray[400]} solid;
		.item {
			padding: 0.4rem;
			flex: 1;
			display: flex;
			justify-content: center;
			text-align: center;
			&:nth-child(1) {
				min-width: 18rem;
			}
			&:nth-child(2) {
				min-width: 18rem;
			}
			&:nth-child(4) {
				min-width: 7rem;
			}
			@media (max-width: 1024px) {
				${({ theme }) => theme.font.body[3]}
				&:nth-child(1) {
					min-width: 10rem;
				}
				&:nth-child(2) {
					min-width: 10rem;
				}
			}
		}
	}
`;

export default ListTable;
