import { isQueryValidCheck } from './isValidCheck';

import type { Sorts } from '../services/types';

/**
 * 객체를 queryString으로 변경
 * @param obj
 * @returns
 */
export const formatQueryString = <T extends Record<string, any>>(obj: T): string => {
	const keys = Object.keys(obj);

	const validatedKeys = keys.filter((key) => isQueryValidCheck(key, obj[key]));

	if (!validatedKeys.length) return '';

	const result = validatedKeys.map((key) => {
		switch (key) {
			case 'sorts': {
				if (Array.isArray(obj[key])) {
					const filterSorts = (obj[key] as Array<Sorts<string>>).filter((sort) => !!sort.direction);
					const arraySort = filterSorts.map((sort) => `${key}=${String(sort.property)};${String(sort.direction)}`);
					return arraySort.join('&');
				} else {
					const sortObj = obj[key] as Sorts<string>;
					return sortObj.property && sortObj.direction
						? `${key}=${String(sortObj.property)};${sortObj.direction}`
						: null;
				}
			}
			case 'startDate':
			case 'endDate':
				return `${key}=${formatDate(new Date(String(obj[key])))}`;
			default:
				if (Array.isArray(obj[key])) {
					const arrayObj = (obj[key] as unknown[]).map((el) => `${key}=${String(el)}`);
					return arrayObj.join('&');
				} else {
					return `${key}=${String(obj[key])}`;
				}
		}
	});

	return `?${result.join('&')}`;
};

/**
 *
 * @param {Date} date
 * @param format - default : "yyyy-MM-dd"
 * - yyyy : 연
 * - MM : 월
 * - dd : 일
 * - D : 요일
 * - hh : 시
 * - mm : 분
 * - ss : 초
 * @return {string}
 */
export const formatDate = (date: Date | null, format = 'yyyy-MM-dd'): string => {
	if (!date) return '';

	const components: Record<string, string> = {
		yyyy: String(date.getFullYear()),
		MM: String(date.getMonth() + 1).padStart(2, '0'),
		dd: String(date.getDate()).padStart(2, '0'),
		D: ['일', '월', '화', '수', '목', '금', '토'][date.getDay()],
		hh: String(date.getHours()).padStart(2, '0'),
		mm: String(date.getMinutes()).padStart(2, '0'),
		ss: String(date.getSeconds()).padStart(2, '0'),
	};

	return format.replace(/yyyy|MM|dd|D|hh|mm|ss/g, (match) => components[match]);
};
