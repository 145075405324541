import type { Sorts } from '../services/types';

export const isValidCheck = (value: unknown) => {
	switch (typeof value) {
		case 'string':
			return value.trim() !== '' && value !== 'ALL';
		case 'number':
			return value !== -1 && !isNaN(value);
		case 'object':
			if (Array.isArray(value)) {
				const validArray = value.filter((el) => el !== null && el !== undefined && el !== 'ALL' && el !== -1);
				return validArray.length > 0;
			}
			return !!value && Object.values(value).every((v) => v !== null && v !== undefined && v !== 'ALL');
		default: {
			return value !== null && value !== undefined && value !== 'ALL';
		}
	}
};

export const isQueryValidCheck = (key: string, value: unknown) => {
	switch (key) {
		case 'sorts': {
			const typeValue = value as Sorts<string> | Array<Sorts<string>>;
			if (Array.isArray(typeValue)) {
				return typeValue.length > 0;
			} else {
				return isValidCheck(typeValue.property);
			}
		}
		default:
			return isValidCheck(value);
	}
};
