import { useEffect } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';
import { type AxiosError } from 'axios';

import { api } from '..';
import usePagination from '../../components/Pagination/hooks/usePagination';
import { useValues } from '../../hooks/useValues';
import { type CustomError } from '../types';

import type { GetPdsReq, PostPasswordReq, PostPasswordRes } from './types';

export const pdsKeys = {
	all: ['pds'],
	getPds: ({ page, limit, ...values }: GetPdsReq) => [...pdsKeys.all, 'list', values, page, limit],
	postPdsPassword: () => [...pdsKeys.all, 'password'],
} as const;

const INITIAL_VALUES: GetPdsReq = {
	page: 1,
	limit: 10,
	sorts: {
		property: 'createdAt',
		direction: 'DESC',
	},
} as const;

export const useGetPds = () => {
	const queries = useValues(INITIAL_VALUES);
	const pagination = usePagination();
	const { curPage, listCount, setPagination } = pagination;

	const queryInfo = useQuery({
		queryKey: pdsKeys.getPds({
			...queries.values,
			page: curPage,
			limit: listCount,
		}),
		queryFn: async () =>
			await api.pds.getPds({
				...queries.values,
				page: curPage,
				limit: listCount,
			}),
	});

	useEffect(() => {
		if (queryInfo.isSuccess) setPagination({ totalCount: queryInfo.data.total });
	}, [queryInfo.data]);

	return { queries, pagination, ...queryInfo };
};

export const usePostPdsPassword = () =>
	useMutation<PostPasswordRes, AxiosError<CustomError>, PostPasswordReq>({
		mutationKey: pdsKeys.postPdsPassword(),
		mutationFn: async (params) => await api.pds.postPdsPassword(params),
	});
