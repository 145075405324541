type SetToken = (access: string, refresh?: string) => void;

/**
 * token setting 후 auth event 발생
 * @param access accessToken 값
 * @param refresh refreshToken 값 (optional)
 */
const setToken: SetToken = (access, refresh) => {
	window.sessionStorage.setItem('accessToken', access);
	if (refresh) window.sessionStorage.setItem('refreshToken', refresh);
	window.dispatchEvent(new Event('auth'));
};

/**
 * token 삭제 후 auth 이벤트 발생
 */
const removeToken = () => {
	window.sessionStorage.removeItem('accessToken');
	window.sessionStorage.removeItem('refreshToken');
	window.dispatchEvent(new Event('auth'));
};

export { setToken, removeToken };
