import { formatQueryString } from '../../utils/format';
import { Get, Post } from '../index';

import type { GetPdsReq, GetPdsRes, PostPasswordReq, PostPasswordRes } from './types';

const BASE_URL = '/pds';

export const pds = {
	getPds: async <T = GetPdsRes>(params: GetPdsReq): Promise<T> => {
		return (await Get<T>(`${BASE_URL}${formatQueryString(params)}`)).data;
	},
	postPdsPassword: async <T = PostPasswordRes>(params: PostPasswordReq): Promise<T> =>
		(await Post<T>(BASE_URL + `/password`, params)).data,
};
